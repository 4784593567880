* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Fade in animation */
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


/* Navbar styles */
.navbar {
  background-color: #343a40;
  padding: 0.5rem 1rem;
  font-family: Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0.02rem;
}

.navbar-brand {
  font-size: 1.5rem;
  color: #f8f9fa; /* Changed color to a lighter shade */
  text-decoration: none;
  margin-left: -15rem;
  transition: color 0.2s ease-in-out; /* Add transition for hover effect */
}

.navbar-brand:hover {
  color: #ccc; /* Change color on hover */
}

.navbar-links a {
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  margin-left: 1rem;
}

.navbar-links a:hover {
  color: #ccc;
}

/* Jumbotron styles */
.custom-jumbotron {
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  margin: 0;
}

.jumbotron-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/public/Images/banner-image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  z-index: -1;
}

.jumbotron-text {
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 100x;
  margin: 0;
}

.features-section {
  padding: 0rem 0 10rem; /* Increase padding */
}
.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}


.section-description {
  font-size: 1.5rem; /* Increase font size */
  line-height: 1.8; /* Adjust line-height for better readability */
  margin-bottom: 2rem; /* Add some margin below the description */
  text-align: justify; /* Justify the text to make it look nicer */
}
.gradient-background {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.feature-card {
  text-align: center;
  border: none;
  margin: 0 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa; /* Add background color */
  padding: 2rem; /* Add padding */
}

.feature-icon {
  font-size: 3rem; /* Increase the font size of the icons */
  margin-bottom: 1rem; /* Add some margin below the icons */
}

.feature-card h2 {
  font-size: 2rem; /* Increase font size */
  margin-bottom: 1rem;
}

.feature-card p {
  font-size: 1.2rem; /* Increase font size */
  line-height: 1.5;
}

/* Jumbotron text scaling */
.custom-jumbotron .jumbotron-text h1 {
  font-size: 4rem;
}

.custom-jumbotron .jumbotron-text p {
  font-size: 2rem;
}

/* Other styles */

/* Default height for mobile devices */
.custom-jumbotron {
  height: 300px;
}

/* Adjust height for tablets and larger devices */
@media screen and (min-width: 768px) {
  .custom-jumbotron {
    height: 500px;
  }
}

/* Adjust height for desktop screens */
@media screen and (min-width: 992px) {
  .custom-jumbotron {
    height: 700px;
  }
}
/* Adjust height for large desktop screens */
@media screen and (min-width: 1200px) {
  .custom-jumbotron {
    height: 900px;
  }
}